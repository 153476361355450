import React, { useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes for type-checking
import { useUser } from "../../contexts/UserContext";
import Toast from "../Toast/Toast";
import { API_URL } from "../../config"; // Import the API URL from the config

function CreateChargeSheet({ onCreateChargeSheet }) {
  const { user, token } = useUser();
  const [chargeSheetName, setChargeSheetName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleCreateChargeSheet = async () => {
    if (!chargeSheetName) {
      setError("Charge sheet name is required.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const apiUrl = `${API_URL}/api/create-user-chargesheet`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserID: user.id,
          CreatedAt: new Date().toISOString(),
          ChargeSheetNameDefault: chargeSheetName,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        setError(
          `Failed to create charge sheet: ${response.status}, ${errorText}`,
        );
        return;
      }

      const newChargeSheet = await response.json();
      newChargeSheet.ChargeSheetNameDefault = chargeSheetName; // Ensure the correct name is set
      setToastMessage("Charge sheet created successfully!");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setChargeSheetName("");
        onCreateChargeSheet(newChargeSheet); // Pass the new charge sheet to the callback
      }, 2000); // Adjust the timeout as needed
    } catch (err) {
      setError(`An error occurred: ${err.toString()}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-4 p-6 bg-gray-800 text-white rounded-lg shadow-md">
      <h2 className="text-lg font-semibold mb-4 text-center">
        Create New Charge Sheet
      </h2>
      <input
        type="text"
        value={chargeSheetName}
        onChange={(e) => {
          setChargeSheetName(e.target.value);
          setError(null);
        }}
        maxLength={50}
        placeholder="Enter charge sheet name"
        className="w-full px-4 py-2 rounded bg-gray-700 border border-gray-500 text-white placeholder-gray-400 focus:outline-none focus:border-teal-500 focus:ring-2 focus:ring-teal-500 transition duration-300"
      />
      <button
        onClick={handleCreateChargeSheet}
        disabled={loading || !chargeSheetName}
        className={`mt-4 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out w-full ${loading ? "bg-teal-700" : "bg-teal-500 hover:bg-teal-700"
          }`}
      >
        {loading ? "Creating..." : "Create Charge Sheet"}
      </button>
      {error && <div className="text-red-500 mt-2">{error}</div>}
      {showToast && (
        <Toast message={toastMessage} onClose={() => setShowToast(false)} />
      )}
    </div>
  );
}

CreateChargeSheet.propTypes = {
  onCreateChargeSheet: PropTypes.func.isRequired,
};

export default CreateChargeSheet;
