import React, { useState, useEffect } from "react";
import Note from "./Note";
import { v4 as uuidv4 } from "uuid";

function Notepad() {
  const [notes, setNotes] = useState(() => {
    const savedNotes = localStorage.getItem("notes");
    return savedNotes ? JSON.parse(savedNotes) : [];
  });
  const [noteInput, setNoteInput] = useState({ title: "", content: "" });

  useEffect(() => {
    localStorage.setItem("notes", JSON.stringify(notes));
  }, [notes]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNoteInput({ ...noteInput, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!noteInput.title || !noteInput.content) {
      alert("Both Title and content are required");
      return;
    }

    const newNote = { ...noteInput, id: uuidv4() };
    setNotes([...notes, newNote]);
    setNoteInput({ title: "", content: "" });
  };

  const handleDelete = (id) => {
    const updatedNotes = notes.filter((note) => note.id !== id);
    setNotes(updatedNotes);
  };

  return (
    <div className="notepad-container bg-gray-800 p-6 flex flex-col justify-center items-center min-h-screen rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-teal-400 mb-6">Note Taking!</h1>
      <form onSubmit={handleSubmit} className="mb-4 space-y-4 w-full max-w-md">
        <input
          name="title"
          type="text"
          value={noteInput.title}
          onChange={handleInputChange}
          placeholder="Note Title"
          className="block w-full px-3 py-2 bg-gray-900 border border-gray-600 rounded-md shadow-sm text-gray-300 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-teal-500 transition duration-300"
        />
        <textarea
          name="content"
          value={noteInput.content}
          onChange={handleInputChange}
          placeholder="Free Text Area"
          className="block w-full px-3 py-2 bg-gray-900 border border-gray-600 rounded-md shadow-sm text-gray-300 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-teal-500 transition duration-300"
        />
        <button
          type="submit"
          className="w-full px-4 py-2 bg-teal-600 hover:bg-teal-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 transition ease-in duration-200"
        >
          Add Note
        </button>
      </form>
      <div className="notes-container flex flex-wrap justify-center gap-4">
        {notes.map((note) => (
          <Note
            key={note.id}
            id={note.id}
            title={note.title}
            content={note.content}
            onDelete={handleDelete}
          />
        ))}
      </div>
    </div>
  );
}

export default Notepad;
