import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyCheckDollar,
  faHospitalUser,
  faSearch,
  faBars,
  faFileInvoiceDollar,
  faMap,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";

function Drawer({ isOpen, toggleDrawer }) {

  return (
    <div
      className={`fixed top-0 left-0 h-full bg-gray-800 text-white transition-all duration-300 ease-in-out ${
        isOpen ? "w-64" : "w-16"
      } overflow-hidden`}
    >
      <div className="flex justify-between items-center p-4">
        <NavLink to="/" className="w-full">
          <img src="/logoName.png" alt="Company Name" className="mt-2 w-full" />
        </NavLink>
        <button onClick={toggleDrawer} className="p-2">
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
      </div>
      <nav className="mt-5">
        <ul>
          <NavItem to="/app/map" icon={faMap} isOpen={isOpen} label="Map" />
          <NavItem
            to="/app/chargesheets"
            icon={faFileInvoiceDollar}
            isOpen={isOpen}
            label="ChargeSheet"
          />
          <NavItem
            to="/app/chargeestimator"
            icon={faSearch}
            isOpen={isOpen}
            label="Estimator"
          />
          <NavItem
            to="/app/analysis"
            icon={faFileAlt}
            isOpen={isOpen}
            label="Analysis"
          />
          <NavItem
            to="/app/chargemaster"
            icon={faMoneyCheckDollar}
            isOpen={isOpen}
            label="ChargeMaster"
          />
          <NavItem
            to="/app/eligibilitytool"
            icon={faHospitalUser}
            isOpen={isOpen}
            label="Eligibility"
          />
          <NavItem
            to="/app/singlechargesearch"
            icon={faSearch}
            isOpen={isOpen}
            label="Single Charge Search"
          />
        </ul>
      </nav>
    </div>
  );
}

function NavItem({ to, icon, isOpen, label }) {
  return (
    <li>
      <NavLink to={to} className="flex items-center p-2 hover:bg-gray-700">
        <FontAwesomeIcon icon={icon} className="mr-2" />
        {isOpen && <span>{label}</span>}
      </NavLink>
    </li>
  );
}

export default Drawer;
