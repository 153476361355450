import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { OverlayContext } from "../../contexts/OverlayContext";

const OverlayTab = () => {
  const { overlayState, setOverlayState } = useContext(OverlayContext);

  // Function to toggle the overlay state
  const toggleOverlay = () => {
    setOverlayState((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
    }));
  };

  return (
    <div
      className={`fixed top-1/2 right-0 transform -translate-y-1/2 z-50 transition-opacity duration-300 ${overlayState.isOpen ? "opacity-25 pointer-events-none" : "opacity-100"
        }`}
    >
      <button
        onClick={toggleOverlay}
        className="bg-gray-600 text-white py-2 px-4 rounded-l-lg focus:outline-none hover:bg-teal-500 transition-colors duration-300"
      >
        <FontAwesomeIcon icon={faStickyNote} size="lg" />
        <span className="ml-2">Tools</span>
      </button>
    </div>
  );
};

export default OverlayTab;
