import React, { useState, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./contexts/UserContext";
import { OverlayProvider } from "./contexts/OverlayContext";
import { ChargeSheetProvider } from "./contexts/ChargeSheetContext";
import { ActiveHospitalLocationProvider } from "./contexts/ActiveHospitalLocationContext";
import { ChargeSheetDetailsProvider } from "./contexts/ChargeSheetDetailsContext";
import Drawer from "./components/navigation/Drawer";
import TabbedOverlay from "./components/overlays/TabbedOverlay";
import OverlayTab from "./components/overlays/OverlayTab";
import "./App.css";
import ConsultingPage from "./Website/ConsultingPage/ConsultingServices"
import ConsultingServices from "./Website/ConsultingPage/ConsultingServices";

// Lazy load components
const MainContent = lazy(() => import("./MainContent"));
const LandingPage = lazy(() => import("./Website/LandingPage"));
const AboutUs = lazy(() => import("./Website/AboutUs"));
const ContactUs = lazy(() => import("./Website/ContactUs"));
const FAQ = lazy(() => import("./Website/FAQ"));
const Login = lazy(() => import("./components/login/Login"));

function App() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="consulting" element={<ConsultingServices />} />

          {/* Protected routes with /app prefix */}
          <Route
            path="/app/*"
            element={
              <UserProvider>
                <ChargeSheetProvider>
                  <ActiveHospitalLocationProvider>
                    <ChargeSheetDetailsProvider>
                      <OverlayProvider>
                        <div className="flex min-h-screen bg-gray-100 text-gray-800 relative">
                          <Drawer
                            isOpen={isDrawerOpen}
                            toggleDrawer={() => setDrawerOpen(!isDrawerOpen)}
                          />
                          <div
                            className={`flex-grow transition-all duration-300 ease-in-out ${isDrawerOpen ? "ml-64" : "ml-12"
                              }`}
                          >
                            <Routes>
                              <Route path="login" element={<Login />} />
                              <Route path="/*" element={<MainContent />} />
                            </Routes>
                          </div>
                          <TabbedOverlay />
                          <OverlayTab />
                        </div>
                      </OverlayProvider>
                    </ChargeSheetDetailsProvider>
                  </ActiveHospitalLocationProvider>
                </ChargeSheetProvider>
              </UserProvider>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
