import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { useUser } from "./UserContext";
import { useChargeSheet } from "./ChargeSheetContext";
import { API_URL } from "../config"; // Import the API URL from the config

// Define the context with default values
const ChargeSheetDetailsContext = createContext({
  activeChargeSheetDetails: [],
  updateChargeSheetDetails: () => {},
  addChargeSheetDetail: () => {},
});

export const ChargeSheetDetailsProvider = ({ children }) => {
  const [activeChargeSheetDetails, setActiveChargeSheetDetails] = useState([]);
  const { activeChargeSheet } = useChargeSheet();
  const { user, token } = useUser();

  // Fetch details for the active charge sheet
  const fetchActiveChargeSheetDetails = useCallback(async () => {
    if (!activeChargeSheet || !activeChargeSheet.UserChargeSheetID) return;

    try {
      const detailsPromises = activeChargeSheet.details.map(async (detail) => {
        const response = await fetch(`${API_URL}/api/get-charges`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            systemID: detail.SystemID,
            locationID: detail.LocationID,
            chargeID: detail.ChargeID,
          }),
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch charges for detail ID: ${detail.ChargeSheetDetailsID}`,
          );
        }

        const chargesData = await response.json();
        return { ...detail, Charges: chargesData[0] }; // Assuming the API response is an array
      });

      const detailsWithCharges = await Promise.all(detailsPromises);
      console.log(
        "Fetched active charge sheet details with charges:",
        detailsWithCharges,
      );
      setActiveChargeSheetDetails(detailsWithCharges);
    } catch (error) {
      console.error("Failed to fetch active charge sheet details:", error);
    }
  }, [activeChargeSheet, token]);

  // Fetch details when the active charge sheet changes
  useEffect(() => {
    if (activeChargeSheet) {
      fetchActiveChargeSheetDetails();
    } else {
      setActiveChargeSheetDetails([]);
    }
  }, [activeChargeSheet, fetchActiveChargeSheetDetails]);

  // Update the charge sheet details
  const updateChargeSheetDetails = (details) => {
    console.log("Updating active charge sheet details:", details);
    setActiveChargeSheetDetails(details);
  };

  // Add a new detail to the charge sheet
  const addChargeSheetDetail = async (newDetail) => {
    setActiveChargeSheetDetails((prevDetails) => [...prevDetails, newDetail]);
    fetchActiveChargeSheetDetails();
  };

  return (
    <ChargeSheetDetailsContext.Provider
      value={{
        activeChargeSheetDetails,
        updateChargeSheetDetails,
        addChargeSheetDetail,
      }}
    >
      {children}
    </ChargeSheetDetailsContext.Provider>
  );
};

// Custom hook to use the ChargeSheetDetailsContext
export const useChargeSheetDetails = () =>
  useContext(ChargeSheetDetailsContext);

ChargeSheetDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChargeSheetDetailsProvider;
