import React, { useState } from "react";
import { API_URL } from "../config"; // Import the API_URL from config.js

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    useremail: "",
    message: "",
  });

  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setStatus("Sending...");

    try {
      const response = await fetch(`${API_URL}/contact-submission`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus("Message sent successfully!");
        setFormData({
          name: "",
          useremail: "",
          message: "",
        });
      } else {
        const errorData = await response.json();
        console.error("Error response from server: ", errorData);
        setStatus("Error sending message.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setStatus("Error sending message.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center p-4" id="contact-us">
      <header className="bg-customTealLight w-full py-12 text-center text-white text-4xl font-bold shadow-lg">
        Contact Us
      </header>
      <main className="flex flex-col items-center justify-center flex-grow p-6 w-full max-w-4xl bg-gray-800 shadow-lg rounded-lg mt-6">
        <h1 className="text-4xl font-bold mb-6 text-white">Get in Touch</h1>
        <p className="text-lg mb-8 text-center max-w-lg text-gray-300">
          Have any questions? We'd love to hear from you. Reach out to us at
          <a href="mailto:support@smithtech.io" className="text-teal-400 hover:underline">
            {" "}support@smithtech.io
          </a>
          , or use the form below.
        </p>
        <form
          onSubmit={handleSubmit}
          className="w-full max-w-md bg-gray-700 shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label
              className="block text-gray-300 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-800 text-gray-300"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-300 text-sm font-bold mb-2"
              htmlFor="useremail"
            >
              Email
            </label>
            <input
              type="email"
              id="useremail"
              name="useremail"
              value={formData.useremail}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-800 text-gray-300"
              required
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-300 text-sm font-bold mb-2"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-800 text-gray-300"
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-customTealMedium hover:bg-teal-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-200"
            >
              Send
            </button>
          </div>
          {status && <p className="text-center mt-4 text-gray-300">{status}</p>}
        </form>
      </main>
    </div>
  );
}

export default ContactUs;
