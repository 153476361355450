import React from "react";
import { HashLink as Link } from "react-router-hash-link";

function Footer() {
  return (
    <footer className="bg-gray-800 w-full py-4 text-center text-white flex justify-center space-x-4">
      <Link to="/#about-us" className="hover:underline" aria-label="About Us">
        About Us
      </Link>
      <Link to="/#contact-us" className="hover:underline" aria-label="Contact Us">
        Contact Us
      </Link>
      <Link to="/#faq" className="hover:underline" aria-label="FAQ">
        FAQ
      </Link>
      <Link to="/" className="hover:underline" aria-label="Back to Home">
        Back to Home
      </Link>
    </footer>
  );
}

export default Footer;
