import React from "react";
import PropTypes from "prop-types";

function Note({ id, title, content, onDelete }) {
  return (
    <div className="note bg-gray-800 text-white shadow-lg rounded-lg p-4 m-2 max-w-sm w-full transition-transform duration-300 hover:scale-105 hover:shadow-xl">
      <h2 className="font-bold text-teal-400 text-lg mb-2">{title}</h2>
      <p className="text-gray-300 mb-4">{content}</p>
      <button
        onClick={() => onDelete(id)}
        className="delete-button bg-red-600 hover:bg-red-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 transition duration-150 ease-in-out"
      >
        Delete
      </button>
    </div>
  );
}

Note.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Note;
