import React, { useState } from "react";
import PropTypes from "prop-types";
import Notepad from "../notepad/Notepad";
import OverlayChargeSheet from "../../components/ChargeSheetHome/OverlayChargeSheet";
import { useChargeSheetDetails } from "../../contexts/ChargeSheetDetailsContext";
import { useUser } from "../../contexts/UserContext";

const TabbedOverlay = ({ isOpen, closeOverlay }) => {
  const [activeTab, setActiveTab] = useState("notepad");
  const { user } = useUser();
  const { activeChargeSheetDetails } = useChargeSheetDetails();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Do not render if overlay is not open
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-gray-900 bg-opacity-50 flex justify-end transition-opacity duration-300">
      <div className="bg-gray-800 w-full max-w-md h-full shadow-2xl overflow-hidden relative flex flex-col transform transition-transform duration-300">
        <div className="flex justify-between items-center p-4 bg-gray-900">
          <h2 className="text-lg font-semibold text-teal-400">Tools</h2>
          <button
            onClick={closeOverlay}
            className="text-white bg-red-600 hover:bg-red-800 px-3 py-1 rounded focus:outline-none transition-colors duration-300"
            aria-label="Close overlay"
          >
            Close
          </button>
        </div>
        <div className="flex justify-around bg-gray-700 text-white">
          <button
            onClick={() => handleTabClick("notepad")}
            className={`py-2 px-4 transition-colors duration-300 ${
              activeTab === "notepad"
                ? "bg-gray-600"
                : "bg-gray-700 hover:bg-gray-600"
            }`}
            aria-selected={activeTab === "notepad"}
          >
            Notepad
          </button>
          <button
            onClick={() => handleTabClick("chargesheet")}
            className={`py-2 px-4 transition-colors duration-300 ${
              activeTab === "chargesheet"
                ? "bg-gray-600"
                : "bg-gray-700 hover:bg-gray-600"
            }`}
            aria-selected={activeTab === "chargesheet"}
          >
            ChargeSheet
          </button>
        </div>
        <div className="p-4 flex-grow overflow-y-auto">
          {activeTab === "notepad" && <Notepad />}
          {activeTab === "chargesheet" && <OverlayChargeSheet />}
        </div>
      </div>
    </div>
  );
};

TabbedOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeOverlay: PropTypes.func.isRequired,
};

export default TabbedOverlay;
