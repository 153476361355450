import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useUser } from "../../contexts/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import { API_URL } from "../../config";

function ChargeSheetDetails({ userChargeSheetID, details }) {
  const { token, user } = useUser(); // Ensure useUser is called at the top level
  const [chargeSheetDetails, setChargeSheetDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedCharges, setExpandedCharges] = useState({});

  const fetchCharges = useCallback(async () => {
    setLoading(true);
    try {
      const chargesPromises = details.map(async (detail) => {
        const response = await fetch(`${API_URL}/api/get-charges`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            systemID: detail.SystemID,
            locationID: detail.LocationID,
            chargeID: detail.ChargeID,
          }),
        });
        const chargesData = await response.json();
        return { ...detail, Charges: chargesData };
      });

      const detailsWithCharges = await Promise.all(chargesPromises);
      setChargeSheetDetails(detailsWithCharges);
    } catch (error) {
      setError(`Failed to fetch charges: ${error.toString()}`);
    } finally {
      setLoading(false);
    }
  }, [details, token]);

  useEffect(() => {
    fetchCharges();
  }, [fetchCharges]);

  const handleDeleteCharge = async (chargeSheetDetailsID) => {
    if (!window.confirm("Are you sure you want to delete this charge?")) {
      return;
    }

    setLoading(true);
    setError(null);

    const payload = {
      ChargeSheetDetailsID: chargeSheetDetailsID,
      UserChargeSheetID: userChargeSheetID,
      UserID: user.id,
    };

    try {
      const response = await fetch(`${API_URL}/api/delete-chargesheet-detail`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        setError(
          `Failed to delete charge sheet detail: ${response.status}, ${errorText}`,
        );
        return;
      }

      setChargeSheetDetails((prevDetails) =>
        prevDetails.filter(
          (detail) => detail.ChargeSheetDetailsID !== chargeSheetDetailsID,
        ),
      );
    } catch (error) {
      setError(`An error occurred: ${error.toString()}`);
    } finally {
      setLoading(false);
    }
  };

  const toggleExpandCharge = (chargeID) => {
    setExpandedCharges((prev) => ({
      ...prev,
      [chargeID]: !prev[chargeID],
    }));
  };

  if (loading)
    return (
      <div className="flex justify-center items-center min-h-screen">
        <ClipLoader color="#4A90E2" loading={true} size={50} />
      </div>
    );
  if (error) return <div className="text-red-500 text-center">{error}</div>;
  if (chargeSheetDetails.length === 0)
    return (
      <div className="text-white text-center">
        No details available for this charge sheet.
      </div>
    );

  return (
    <div className="max-w-4xl mx-auto p-4">
      {chargeSheetDetails.map((detail) => (
        <div
          key={detail.ChargeSheetDetailsID}
          className="mb-4 p-4 bg-gray-700 rounded-lg"
        >
          {detail.Charges && detail.Charges.length > 0 ? (
            detail.Charges.map((charge) => (
              <div key={charge.ChargeID} className="mb-2">
                <div className="flex justify-between items-center">
                  <div className="flex-1">
                    {charge.ServiceDescription && (
                      <p className="text-lg font-bold text-yellow-400 mb-2 break-words">
                        {charge.ServiceDescription}
                      </p>
                    )}
                    {charge.BillingCode && (
                      <p className="text-md font-semibold text-green-400 mb-2">
                        {charge.BillingCode}
                      </p>
                    )}
                    {charge.LocationName && (
                      <p className="text-md font-semibold text-blue-400 mb-2">
                        {charge.LocationName}
                      </p>
                    )}
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() =>
                        handleDeleteCharge(detail.ChargeSheetDetailsID)
                      }
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded transition duration-300"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                    <button
                      onClick={() => toggleExpandCharge(charge.ChargeID)}
                      className="text-white focus:outline-none"
                    >
                      <FontAwesomeIcon
                        icon={
                          expandedCharges[charge.ChargeID]
                            ? faChevronUp
                            : faChevronDown
                        }
                      />
                    </button>
                  </div>
                </div>
                {expandedCharges[charge.ChargeID] && (
                  <div className="mt-2 max-h-32 overflow-y-auto">
                    {Object.entries(charge).map(([key, value]) => {
                      if (
                        [
                          "LocationID",
                          "SystemID",
                          "ChargeID",
                          "IsDeleted",
                          "ChargeSheetDetailsID",
                          "UserChargeSheetID",
                          "UserID",
                          "ChargeCode",
                          "ServiceDescription",
                          "BillingCode",
                          "LocationName",
                        ].includes(key) ||
                        value === null ||
                        typeof value === "object"
                      ) {
                        return null;
                      }
                      return (
                        <p key={key} className="text-white text-sm">
                          <strong>{key}: </strong>
                          {value}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="text-red-500">
              No charges available for this detail.
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

ChargeSheetDetails.propTypes = {
  userChargeSheetID: PropTypes.string.isRequired,
  details: PropTypes.array.isRequired,
};

export default ChargeSheetDetails;
