import React from "react";
import { Link } from "react-router-dom";
import "tailwindcss/tailwind.css";
import "./ConsultingServices.css"; // Import additional CSS if needed
import ContactUs from "../ContactUs";
import Footer from "../Footer";

function ConsultingServices() {
  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center p-4">
      <header className="bg-customTealLight w-full py-12 text-center text-white text-4xl font-bold shadow-lg">
        Consulting Services
      </header>

      <main className="flex flex-col items-center flex-grow p-6 w-full max-w-4xl bg-gray-800 shadow-lg rounded-lg mt-6">
        {/* Introduction Section */}
        <section className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4 text-white">
            Empower Your Small Practice with Expert Consulting
          </h1>
          <p className="text-lg text-gray-300 max-w-3xl mx-auto">
            At SmithTech Solutions, we specialize in supporting small practices and solo therapists, particularly in physical therapy and mental health therapy. Navigating the complexities of healthcare credentialing, billing, and insurance can be challenging, especially for solo practitioners. Our consulting services are designed to provide you with the personalized guidance and support you need to grow your practice and improve your financial outcomes.
          </p>
        </section>

        {/* Testimonial Section */}
        <section className="bg-gray-700 p-8 rounded-lg shadow-md mb-12">
          <h2 className="text-3xl font-bold text-center text-teal-400 mb-6">
            Client Success Stories
          </h2>
          <blockquote className="text-lg text-gray-300 italic max-w-3xl mx-auto text-center">
            "Before I started working with SmithTech Solutions, I struggled to justify working with insurance companies even as I knew it was a necessary move for my solo therapy practice. With their support, I was able to overcome my hesitation, navigate the complexities of credentialing, and understand the nuances of insurance paneling. I was also able to complete the process within 4 months for 3 major insurance companies, something that can take up to a year or more for most small practices. If you're a solo practitioner like me, and need guidance through this crucial business process, I highly recommend SmithTech Solutions to make the path smoother."
          </blockquote>
          <p className="text-right mt-4 text-gray-300 font-bold">
            - Satisfied Client
          </p>
        </section>

        {/* Services Overview Section */}
        <section className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-6 text-white">
            Our Consulting Expertise
          </h2>
          <div className="text-lg text-gray-300 max-w-3xl mx-auto">
            <p className="mb-4">
              Our consulting services are specifically tailored to meet the unique needs of small practices and solo therapists. Whether you're a physical therapy clinic, mental health clinic, or any other healthcare professional, we offer the expertise and experience to help you succeed in navigating insurance credentialing and optimizing your revenue cycle management.
            </p>
            <p className="mb-4">
              Key areas of our consulting services include:
            </p>
            <ul className="list-disc list-inside mb-6 text-left">
              <li className="mb-2">Insurance Credentialing and Paneling for Solo Practitioners</li>
              <li className="mb-2">Revenue Cycle Management Optimization for Small Practices</li>
              <li className="mb-2">Billing and Coding Support for Therapists</li>
              <li className="mb-2">Compliance and Regulatory Guidance</li>
              <li className="mb-2">Strategic Business Planning for Practice Growth</li>
            </ul>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-6 text-white">
            Ready to Transform Your Solo Practice?
          </h2>
          <p className="text-lg text-gray-300 max-w-3xl mx-auto mb-8">
            Partner with SmithTech Solutions to gain the personalized insights and support you need to navigate the complexities of the healthcare industry. Contact us today to learn more about how our consulting services can help your small practice thrive.
          </p>
        </section>
      </main>

      {/* Contact Us Section */}
      <section className="w-full bg-gray-900">
        <ContactUs />
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default ConsultingServices;
