import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useUser } from "./UserContext";
import { API_URL } from "../config"; // Import the API URL from the config

// Define the context with default values
const ChargeSheetContext = createContext({
  chargeSheets: [],
  activeChargeSheet: null,
  setActiveChargeSheetInfo: () => { },
  fetchChargeSheets: () => { },
  removeChargeSheet: () => { }, // Add removeChargeSheet function to the context
});

export const ChargeSheetProvider = ({ children }) => {
  const [chargeSheets, setChargeSheets] = useState([]);
  const [activeChargeSheet, setActiveChargeSheet] = useState(null);
  const { user, token } = useUser();

  // Fetch charge sheets for the logged-in user
  const fetchChargeSheets = useCallback(async () => {
    if (!user || !user.id) return;

    try {
      const apiUrl = `${API_URL}/api/user-chargesheets?userID=${user.id}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch charge sheets: ${response.statusText}`,
        );
      }

      const data = await response.json();
      setChargeSheets(data || []);
    } catch (error) {
      console.error("Error fetching charge sheets:", error);
    }
  }, [user, token]);

  // Set the active charge sheet
  const setActiveChargeSheetInfo = (chargeSheet) => {
    setActiveChargeSheet(chargeSheet);
  };

  // Remove a charge sheet
  const removeChargeSheet = (chargeSheetId) => {
    setChargeSheets((prev) =>
      prev.filter((sheet) => sheet.UserChargeSheetID !== chargeSheetId),
    );
  };

  // Fetch charge sheets when the user changes
  useEffect(() => {
    if (user) {
      fetchChargeSheets();
    }
  }, [user, fetchChargeSheets]);

  return (
    <ChargeSheetContext.Provider
      value={{
        chargeSheets,
        activeChargeSheet,
        setActiveChargeSheetInfo,
        fetchChargeSheets,
        removeChargeSheet, // Provide the removeChargeSheet function to the context
      }}
    >
      {children}
    </ChargeSheetContext.Provider>
  );
};

// Custom hook to use the ChargeSheetContext
export const useChargeSheet = () => useContext(ChargeSheetContext);

export default ChargeSheetProvider;
